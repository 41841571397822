import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import constant from '../../../utils/constant';
import Search from '../../../views_admin/layout/Search';
import _capitalize from 'lodash/capitalize';
import _lowerCase from 'lodash/lowerCase';
import OrderList from '../../../views/profile/Order/OrderList';
import { OrderTab } from '@pages/profile/order';
import { ShipmentStatus } from '@utils/enums/ShipmentStatus';
import qs from 'query-string';
import { useLocation } from '@reach/router';
import useUrlUpdate from '@utils/hooks/useUrlUpdate';
import Filter from '@views_admin/shipment/Filter';
import { Link, navigate } from 'gatsby';
import BtnAddShippingFee from '@views_admin/shipment/BtnAddShippingFee';
import IconAdd2 from '@icons/IconAdd2';
import SEO from '@components/SEO';

const tabList = [
   {
      label: 'All orders',
      name: OrderTab.all_orders,
      component: OrderList,
      filter: {},
   },
   {
      label: 'Confirmed',
      name: OrderTab.confirmed,
      component: OrderList,
      filter: {
         status: ShipmentStatus.CONFIRMED,
      },
   },
   {
      label: 'Shipping',
      name: OrderTab.shipping,
      component: OrderList,
      filter: {
         status: ShipmentStatus.SHIPPING,
      },
   },
   {
      label: 'Arrived',
      name: OrderTab.arrived,
      component: OrderList,
      filter: {
         status: ShipmentStatus.ARRIVED,
      },
   },
   {
      label: 'Canceled',
      name: OrderTab.canceled,
      component: OrderList,
      filter: {
         status: ShipmentStatus.CANCELED,
      },
   },
];

const Shipment = memo(() => {
   const location = useLocation();
   const { t: tab = '' } = qs.parse(location.search) || {};

   const [currentTab, setCurrentTab] = useState(
      OrderTab[tab] ? tab : OrderTab.all_orders
   );
   useUrlUpdate(`${constant.ROUTE_ADMIN_SHIPMENT}?t=${currentTab}`);

   const [searchText, setSearchText] = useState('');
   const [filter, setFilter] = useState({
      from_date: '',
      to_date: '',
   });

   const changeTab = (tabName) => {
      setCurrentTab(tabName);
   };

   const combinedFilter = useMemo(() => {
      return {
         ...filter,
         search: searchText,
      };
   }, [filter, searchText]);

   const filterSet = useMemo(() => {
      return {
         from_date: filter.from_date,
         to_date: filter.to_date,
      };
   }, [filter]);

   const handleFilter = useCallback((newFilter = {}) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         ...newFilter,
         page: 1,
      }));
   }, []);

   const handleAddFeeSuccess = useCallback(() => {
      navigate(`${constant.ROUTE_ADMIN_SHIPMENT_FEES}`);
   }, []);

   return (
      <>
         <SEO title={'Shipment - Admin'} />
         <div className="content-head">
            <div className="content-head__left">
               <div className="admin-shipment__tabs">
                  <div className={`tab-item active`}>Orders</div>
                  <Link
                     className={`tab-item`}
                     to={`${constant.ROUTE_ADMIN_SHIPMENT_FEES}`}
                  >
                     Shipping Fees
                  </Link>
               </div>
            </div>
            <div className="content-head__right">
               <BtnAddShippingFee
                  className="outline-btn btn-add-new-item"
                  onSuccess={handleAddFeeSuccess}
               >
                  <i>
                     <IconAdd2 />
                  </i>
                  Add Shipping Fee
               </BtnAddShippingFee>
            </div>
         </div>
         <div className="admin-shipment__order-tabs">
            {tabList.map((tabItem) => {
               const isActive = tabItem.name === currentTab;
               return (
                  <div
                     className={`tab-item ${isActive ? 'active' : ''}`}
                     key={tabItem.name}
                     onClick={() => changeTab(tabItem.name)}
                  >
                     {tabItem.label}
                  </div>
               );
            })}
         </div>
         <div className="content-body admin-products__body">
            <div className="admin-table">
               <div className="admin-table__header admin-shipment__table-header">
                  <div className="admin-table__header--left">
                     <Search placeholder="Search..." onSearch={(v) => setSearchText(v)} />
                  </div>
                  <div className="admin-table__header--right">
                     <Filter filterSet={filterSet} onChange={handleFilter} />
                  </div>
               </div>
               {tabList.map((tabItem) => {
                  const isActive = tabItem.name === currentTab;
                  return (
                     <TabItem
                        key={tabItem.name}
                        isActive={isActive}
                        filter={combinedFilter}
                        Component={tabItem.component}
                        tabFilter={tabItem.filter}
                     />
                  );
               })}
            </div>
         </div>
      </>
   );
});

const TabItem = memo(({ isActive, tabFilter, filter, Component }) => {
   const combinedFilter = useMemo(() => {
      return {
         ...filter,
         ...tabFilter,
         manager: true,
      };
   }, [tabFilter, filter]);

   return <Component isActive={isActive} initialFilter={combinedFilter} isManager />;
});

export default Shipment;
