import FileDropzone from '@components/FileDropzone';
import Input from '@components/Input';
import InputUploader from '@components/InputUploader';
import { message } from '@components/Message';
import IconFileDrop from '@icons/IconFileDrop';
import { shippingService } from '@services/shippingService';
import { InputType } from '@utils/enums/InputType';
import { Col, Modal, Row } from 'antd';
import React, { memo, useCallback, useMemo, useState } from 'react';

const acceptedFileExtensions = '.xlsx, .xls';

const BtnAddShippingFee = memo(({ className, onSuccess, ...ownProps }) => {
   const [isOpen, setIsOpen] = useState(false);

   const onCancel = useCallback(() => {
      setIsOpen(false);
   }, []);

   const handleSuccess = useCallback(() => {
      setIsOpen(false);
      onSuccess && onSuccess();
   }, [onSuccess]);

   return (
      <>
         <button className={className} onClick={() => setIsOpen(true)}>
            {ownProps.children || 'Add new'}
         </button>

         <Modal
            className="common-modal"
            wrapClassName="common-modal-container"
            visible={isOpen}
            width={630}
            closable={false}
            maskClosable={false}
            footer={null}
            destroyOnClose={true}
         >
            <ImportFeeContent onCancel={onCancel} onSuccess={handleSuccess} />
         </Modal>
      </>
   );
});

const ImportFeeContent = memo(({ onCancel, onSuccess }) => {
   const [submitting, setSubmitting] = useState(false);
   const [data, setData] = useState({
      file: null,
      vat: '0',
   });
   const [vatError, setVatError] = useState('');

   const handleSubmit = async () => {
      await setSubmitting(true);
      const response = await shippingService.importShippingFee(data);
      if (response.isSuccess) {
         message.success('Import success');
         onSuccess && onSuccess();
      } else {
         message.error(response.message);
      }
      setSubmitting(false);
   };

   const onUpload = useCallback((files) => {
      let newFiles = Array.from(files).map((file) => file);
      let acceptedExtensions = acceptedFileExtensions
         .split(', ')
         .map((ext) => ext.trim());
      const acceptedFiles = newFiles.filter((file) => {
         const extension = file.name.split('.').pop();
         return extension && acceptedExtensions.includes(`.${extension}`);
      });
      if (acceptedFiles?.length) {
         setData((prevData) => ({
            ...prevData,
            file: acceptedFiles[0],
         }));
      }
   }, []);

   const handleChangeVAT = useCallback((value) => {
      setData((prevData) => ({
         ...prevData,
         vat: value,
      }));
      if (value && (Number(value) < 0 || Number(value) > 100)) {
         setVatError('VAT must be between 0 and 100');
      } else {
         setVatError('');
      }
   }, []);

   const canSubmit = useMemo(() => {
      return data.file && data.vat && !vatError && !submitting;
   }, [data, vatError, submitting]);

   return (
      <div className="import-fee">
         <label className="import-fee__header">ADD SHIPPING FEE</label>
         <div className="import-fee__content">
            <FileDropzone
               placeholderClassName="import-fee__dropzone"
               placeholderDropMessage="Drop files for instant upload"
               onChange={onUpload}
               disabled={submitting}
            >
               <div className="import-fee__upload">
                  {!data.file ? (
                     <div className="empty-drop">
                        <span>
                           <span className="bold">Drop file here</span> or
                        </span>
                        <InputUploader
                           className="btn-vape h40 blue1"
                           onChange={onUpload}
                           accept={acceptedFileExtensions}
                        >
                           Browse file
                        </InputUploader>
                     </div>
                  ) : (
                     <div className="uploaded-file">
                        <div>
                           Uploaded file:{' '}
                           <span className="filename">{data.file.name}</span>
                        </div>
                        <InputUploader
                           className="btn-vape h40 blue1"
                           onChange={onUpload}
                           accept={acceptedFileExtensions}
                        >
                           Change file
                        </InputUploader>
                     </div>
                  )}
               </div>
            </FileDropzone>
            <Row>
               <Col lg={4} md={4} sm={4} xs={24}>
                  <label className="field-title">
                     VAT
                     <span className="required"> *</span>
                  </label>
               </Col>
               <Col lg={20} md={20} sm={20} xs={24}>
                  <Input
                     type={InputType.NUMBER}
                     name="vat"
                     value={data.vat}
                     placeholder="VAT"
                     suffix="%"
                     inputClassName="h40"
                     onChange={handleChangeVAT}
                  />
                  {vatError && <span className="error-msg">{vatError}</span>}
               </Col>
            </Row>
         </div>
         <div className="actions">
            <button className="btn-vape h40 white-op" type="button" onClick={onCancel}>
               Cancel
            </button>
            <button
               className="btn-vape h40 blue1"
               type="submit"
               disabled={!canSubmit}
               onClick={handleSubmit}
            >
               {submitting ? 'Saving' : 'Save'}
            </button>
         </div>
      </div>
   );
});

export default BtnAddShippingFee;
