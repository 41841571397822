import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { getFilesWebkitDataTransferItems } from '@utils/fileUtils';
import IconDirectory from '@icons/IconDirectory';

class FileDropzone extends PureComponent {
   state = {
      open: false,
   };

   onDragOver = (e) => {
      if (this.props.disabled) {
         return;
      }
      e.preventDefault();
      clearTimeout(this.timerRemove);
   };

   openDropzone = (e) => {
      if (this.props.disabled) {
         return;
      }
      e.preventDefault();
      clearTimeout(this.timerRemove);
      if (!this.props.disabled && !this.state.open) {
         const transferTypes = e.dataTransfer.types;
         if (transferTypes.some((t) => t === 'Files')) {
            this.setState({
               open: true,
            });
         }
      }
   };

   removeDrop = (e) => {
      e.preventDefault();
      this.timerRemove = setTimeout(() => {
         if (this.state.open) {
            this.setState({
               open: false,
            });
         }
      }, 0);
   };

   onDrop = async (e) => {
      if (this.props.disabled) {
         return;
      }
      e.preventDefault();
      const files = await getFilesWebkitDataTransferItems(e.dataTransfer.items);
      if (files?.length !== 0) {
         if (this.props.onChange) {
            this.props.onChange(files);
         }
         this.setState({
            open: false,
         });
      }
   };

   render() {
      const {
         className,
         placeholderClassName,
         placeholderDropMessage,
         disabled,
      } = this.props;
      return (
         <div
            className={classNames('file-dropzone', {
               open: this.state.open && !disabled,
               [className]: className,
            })}
            onDragEnter={this.openDropzone}
            onDragOver={this.onDragOver}
            onDragLeave={this.removeDrop}
            onDrop={this.onDrop}
         >
            {this.state.open && (
               <div
                  className={classNames('drop-placeholder', {
                     [placeholderClassName]: placeholderClassName,
                  })}
               >
                  <span className="drop-placeholder__content">
                     <div className="drop-placeholder__message">
                        {placeholderDropMessage || (
                           <>
                              <span>Drop files to instantly upload to:</span>
                              <div>
                                 <IconDirectory /> My library
                              </div>
                           </>
                        )}
                     </div>
                  </span>
               </div>
            )}
            {this.props.children}
         </div>
      );
   }
}

export default FileDropzone;
