import React from 'react';

const IconDirectory = () => (
   <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M1.5 12.5V3.5C1.5 2.39543 2.39543 1.5 3.5 1.5L6.42964 1.50001C7.09834 1.50001 7.7228 1.83421 8.09373 2.3906L8.90627 3.6094C9.2772 4.1658 9.90166 4.5 10.5704 4.5H13.5C14.6046 4.5 15.5 5.39543 15.5 6.5V12.5C15.5 13.6046 14.6046 14.5 13.5 14.5H3.5C2.39543 14.5 1.5 13.6046 1.5 12.5Z"
         stroke="currentColor"
         strokeWidth="1.5"
      />
   </svg>
);

export default IconDirectory;