import React, { memo, useRef, useState } from 'react';

const InputUploader = memo(({
   className, children, accept, multiple, disabled, readOnly,
   onChange,
}) => {
   const inputRef = useRef();

   const handleChange = (e) => {
      e.stopPropagation();
      e.preventDefault();
      const files = e.target.files;
      if (onChange) {
         onChange(files);
      }
   }

   const openUpload = () => {
      if (!readOnly && !disabled) {
         if (inputRef.current) {
            inputRef.current.value = [];
            inputRef.current.click();
         }
      }
   }

   return (
      <>
         <input
            style={{
               display: 'none',
            }}
            ref={inputRef}
            type="file"
            onChange={handleChange}
            accept={accept || ''}
            multiple={multiple}
            disabled={disabled}
            readOnly={readOnly}
         />
         <button
            className={`input-uploader ${className || ''} ${(disabled || readOnly) ? 'disabled' : ''}`}
            disabled={disabled}
            onClick={openUpload}
         >
            {children}
         </button>
      </>
   );
});

export default InputUploader;